// logo



.logo{
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 120em / 53;
    line-height: 1em;
    transition: .2s;
    &, &:hover{
        text-decoration: none;
    }
    &:before {
        content: "\00a0";
    }
    svg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

