// welcome-facts



.welcome-facts{
    position: relative;


    .carousel-cell{
        position: absolute;
        top: -99999px;
        left: -99999px;
        &:first-child{
            position: relative;
            top: auto;
            left: auto;
        }
    }
    .flickity-slider .carousel-cell{
        position: relative;
        top: auto;
        left: auto;
        transition-duration: 1 s;
        &.is-selected{
            transition-delay: .5s;
        }
    }


    &_i{
        margin: 57px 0 0;
        &:first-child{
            margin: 0;
        }
    }
    &_head{
        font-family: @ff-russian-rail;
        font-size: 38px;
        line-height: 1;
        font-weight: 700;
        color: #ff0025;
    }
    &_text{
        margin: 8px 0 0;
        font-family: @ff-roboto-cond;
        font-size: 16px;
        line-height: 19/16;
    }

    @media (max-width: @screen-tp-max) {
        &_head{
            font-size: 18px;
        }
        &_text{
            font-size: 14px;
        }
    }
}