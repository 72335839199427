// Forms.less
// Base styles for various input types, form layouts, and states
// -------------------------------------------------------------


// GENERAL STYLES
// --------------


// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-apperance: none;
}


// Make all forms have space below them
form {
  margin: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}


// Identify controls by their labels
label {
    display: block;
    cursor: default;
}
label[for]{
    cursor: pointer;
}

// Remove the cross from the IE 10
input[type="text"]::-ms-clear{
    display: none;
}

select[multiple],
select[size] {
    height: auto;
}




.form-control{
    &:extend(.form-control-mix all);
}




// Fieldset
.fieldset{
    position: relative;
    &:extend(.cf all);
    & + &{
        margin-top: 20px;
    }
    &:first-child{
        margin-top: 0;
    }

    &.error{
        label{
            color: #c0392b;
        }
        input, textarea{
            border-color: #c0392b;
        }
    }

    label{
        line-height: 18px;
    }
}


