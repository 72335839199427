// breadcrumbs



.breadcrumbs{
    margin: 0 0 29px;
    padding: 40px 0 20px;
    list-style: none;
    font-size: 0;
    border-bottom: 1px solid #d8d8d8;
    &_i{
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-family: @ff-roboto-cond;
        font-size: 12px;
        line-height: 14px;
        color: @text-color;
        &:before{
            content: '›';
            display: inline-block;
            vertical-align: top;
            margin: 0 12px 0 11px;
            font-size: 12px;
            color: #625d5a;
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        &:last-child{
            color: #625d5a;
        }
    }
    a&_link{
        color: @text-color;
        &:hover{
            color: @link-hover-color;
        }
    }


    @media (max-width: @screen-tp-max) {
        display: none;
    }
}