// m-page-nav



.m-page-nav{
    &:extend(.cf-mix all);
    margin: 0 -20px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    background: #fff;
    &_back{
        position: relative;
        float: left;
        width: 50px;
        height: 55px;
        border-right: 1px solid #d8d8d8;
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            margin: -12px 0 0 -12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M14.837 5.107l.38.175c.397.397.216 1.199-.413 1.828L9.914 12l4.863 4.863c.628.628.81 1.431.44 1.854-.396.397-1.198.216-1.827-.413L8.196 13.11l-.232-.297-.182-.096c-.247-.471-.221-.23-.111-.717-.11-.487-.136-.246.111-.718l.182-.095.232-.297 5.167-5.167c.759-.592.291-.338 1.474-.616z' fill='%23454553'/%3E %3C/svg%3E");
        }
    }
    &_cont{
        position: relative;
        margin: 0 0 0 51px;
        &.open{
            .m-page-nav_drop{
                opacity: 1;
                visibility: visible;
                transform: scaleY(1);
            }
        }
    }
    &_name{
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 20px 30px 19px 20px;
        font-family: @ff-roboto-cond;
        font-size: 14px;
        line-height: 16px;
        color: #756f6d;
        text-transform: uppercase;
        white-space: nowrap;
        cursor: pointer;
        &:before{
            content: '';
            position: absolute;
            top: 15px;
            right: 0;
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M5.647 8.591c1.172.265.707.016 1.463.605l4.89 4.89 4.863-4.863c.628-.628 1.431-.81 1.855-.441.396.397.215 1.199-.414 1.828l-5.194 5.194-.297.231-.095.183c-.472.247-.231.221-.718.111-.487.11-.246.136-.718-.111l-.095-.183-.297-.231-5.167-5.167c-.628-.628-.81-1.431-.441-1.855l.365-.191z' fill='%23454553'/%3E %3C/svg%3E");
        }
    }
    &_drop{
        position: absolute;
        z-index: @zindex-dropdown;
        top: 100%;
        left: 0;
        min-width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: .2s;
        transform-origin: 50% 0;
        transform: scaleY(.5);

        box-sizing: content-box;
        margin: 1px 0 0 -51px;
        padding: 0 0 0 51px;
        background: #fff;
        border-bottom: 1px solid #d8d8d8;
    }
    &_list{
        margin: 0;
        padding: 10px 0 10px 20px;
        list-style: none;
        background: #fff;
    }
    &_link{
        display: inline-block;
        vertical-align: top;
        padding: 8px 0;
        color: @text-color;
        text-decoration: none;
    }

    .fixed &{
        position: fixed;
        z-index: @zindex-modal - 10;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        border-top-color: transparent;
    }


    @media (min-width: @screen-tp-min) {
        display: none;
    }
}


.m-page-nav-affix{
    margin-bottom: 20px;


    @media (min-width: @screen-tp-min) {
        display: none;
    }
}