// footer-meta



.footer-meta{
    padding: 8px 0 0;
    &_i{
        margin: 15px 0 0;
        &:first-child{
            margin: 0;
        }
    }
    &_lbl{
        display: inline-block;
        vertical-align: top;
        color: #94918f;
    }
    &_val{
        display: inline-block;
        vertical-align: top;
    }
    &_hl{
        color: #94918f;
    }
    &_copy-sign{
        font-family: @ff-roboto;
    }


    @media (min-width: @screen-tp-min) {
        &_copy{
            .footer-meta_lbl{
                display: none;
            }
        }
        &_phone,
        &_mail{
            display: inline-block;
            vertical-align: top;
        }
        &_mail{
            margin-left: 21px;
        }
    }

    @media (max-width: @screen-pl-max) {
        margin: 0 0 28px;
        &_lbl{
            float: left;
            width: 100px;
            padding: 0 10px 0 0;
            min-height: 1px;
            text-align: right;
        }
        &_val{
            display: block;
            overflow: hidden;
        }
    }
}