// news-list



.news-list{
    margin: 0 -15px;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    &_i{
        display: inline-block;
        vertical-align: top;
        display: flex;
        width: 33.333333%;
        margin: 0 0 30px;
        padding: 0 15px;
        font-family: @ff-russian-rail;
        font-weight: 500;
        word-wrap: break-word;
    }
    &_i-cont{
        width: 100%;
        position: relative;
        background: #f1f1f1;
        &:hover{
            background: darken(#f1f1f1, 10%);
        }
    }
    &_cover{
        background: no-repeat 50% 50% / cover;
        &:before{
            content: '';
            display: block;
            padding: 0 0 percentage(208/303);
            .news-list_name{
                color: #000;
            }
        }
    }
    &_date{
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: -22px 0 -18px -10px;
        padding: 12px 19px 10px 16px;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        background: @base-color;
        &:before{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            border: 5px solid #43040d;
            border-bottom-color: transparent;
            border-left-color: transparent;
        }
    }
    &_name{
        padding: 39px 20px 29px;
        font-size: 18px;
        line-height: 24/18;
        color: @text-color;
    }
    &_link{
        &, &:hover{
            color: inherit;
            text-decoration: none;
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }


    @media (max-width: @screen-tl-max) {
        &_i{
            width: 50%;
        }
    }

    @media (max-width: @screen-pl-max) {
        &_i{
            width: 100%;
        }
    }
}