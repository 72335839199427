// cross


/*
 * @stroke-linecap: round | butt | square
 */

.cross-draw(@size, @stroke-width, @stroke-color, @stroke-linecap, @to, @bo) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='@{size}' height='@{size}' viewBox='0 0 @{size} @{size}' preserveAspectRatio='none'%3E %3Cline stroke-linecap='@{stroke-linecap}' fill='none' stroke='%23@{stroke-color}' stroke-width='@{stroke-width}' x1='@{to}' y1='@{to}' x2='@{bo}' y2='@{bo}'/%3E %3Cline stroke-linecap='@{stroke-linecap}' fill='none' stroke='%23@{stroke-color}' stroke-width='@{stroke-width}' x1='@{bo}' y1='@{to}' x2='@{to}' y2='@{bo}'/%3E %3C/svg%3E");
}

.cross(@size: 100, @stroke-width: 10, @stroke-color-input: #000, @stroke-linecap: round){

    @stroke-color: replace(@stroke-color-input, '#', '');

    & when (@stroke-linecap = round) {
        @lineOffsetMultiple: @stroke-width / 2;
        @to: 0 + @lineOffsetMultiple;
        @bo: @size - @lineOffsetMultiple;
        .cross-draw(@size, @stroke-width, @stroke-color, @stroke-linecap, @to, @bo);
    }
    & when (@stroke-linecap = butt) {
        @lineOffsetMultiple: @stroke-width / 3;
        @to: 0 + @lineOffsetMultiple;
        @bo: @size - @lineOffsetMultiple;
        .cross-draw(@size, @stroke-width, @stroke-color, @stroke-linecap, @to, @bo);
    }
    & when (@stroke-linecap = square) {
        @lineOffsetMultiple: @stroke-width / 1.5;
        @to: 0 + @lineOffsetMultiple;
        @bo: @size - @lineOffsetMultiple;
        .cross-draw(@size, @stroke-width, @stroke-color, @stroke-linecap, @to, @bo);
    }

    background-repeat: no-repeat;
    background-size: cover;
}

