// footer



.footer{
    padding: 32px 0 35px;
    font-family: @ff-russian-rail;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    border-top: 1px solid #c6c6c6;
    a{
        color: @text-color;
        &:hover{
            color: @link-hover-color;
        }
    }

    &_menu{
        margin: 0 0 30px;
        padding: 0 0 20px;
        border-bottom: 1px solid #ccc;
    }

    .nav-open &{
        @media (max-width: @screen-tp-max) {
            padding: 0;
            height: 0;
            border: 0;
            overflow: hidden;
        }
    }
}
