// m-menu



.m-menu{
    //min-height: ~"calc(100vh - 98px)";
    padding: 30px 0;
    background: #fff;
    border-top: 1px solid #ccc;
    box-shadow: 0 10px 15px fade(#000, 15);
    .menu{
        &:extend(.cf-mix all);
        margin: -20px -15px 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        &_i{
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 0 15px;
            margin: 20px 0 0;
        }
        &_i-name{
            padding: 19px 0 17px;
            font-family: @ff-russian-rail;
            font-size: 16px;
            line-height: 18px;
            font-weight: 900;
            color: #39322f;
            text-transform: uppercase;
        }

        @media (min-width: @screen-tp-min) {
            &_i{
                width: 240px;
            }
        }
        @media (max-width: @screen-ld-max) and (min-width: 900px) {
            &_i{
                width: 300px;
            }
        }
    }
    .sub-menu{
        &_i{
            word-wrap: break-word;
            white-space: normal;
        }
    }
}