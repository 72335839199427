// COMPONENT ANIMATIONS
// --------------------



.fade {
  transition: opacity .15s linear;
  opacity: 0;
  &.in {
    opacity: 1;
  }
}

.collapse {
    display: none;

    &.in      { display: block; }
    tr&.in    { display: table-row; }
    tbody&.in { display: table-row-group; }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}
