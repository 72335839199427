// f-links



.f-links{
    margin: 11px 0 0;
    &_i{
        margin: 14px 0 0;
        &:first-child{
            margin: 0;
        }
    }


    @media (max-width: @screen-pl-max) {
        margin: 15px 0 0;
        text-align: center;
        &_i{
            display: inline-block;
            vertical-align: top;
            margin: 0 6px;
        }
    }
}