// mass-media



.mass-media{
    margin: -31px 0 0;
    .contacts{
        [class^="col-"], [class*="col-"]{
            margin-top: 16px;
            &:first-child{
                margin-top: 0;
            }
        }
    }
}