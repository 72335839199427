// f-menu



.f-menu{
    .menu{
        &:extend(.cf-mix all);
        margin: -20px -15px 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        &_i{
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 0 15px;
            margin: 20px 0 0;
        }
        &_i-name{
            padding: 0 0 12px;
            font-family: @ff-russian-rail;
            font-size: 14px;
            line-height: 16px;
            font-weight: 900;
            color: #39322f;
            text-transform: uppercase;
        }

        @media (min-width: @screen-tp-min) {
            &_i{
                width: 240px;
            }
        }
        @media (max-width: @screen-ld-max) and (min-width: 900px) {
            &_i{
                width: 300px;
            }
        }
    }
    .sub-menu{
        &_i{
            padding: 5px 0;
            font-size: 14px;
            line-height: 16px;
            word-wrap: break-word;
            white-space: normal;
        }
    }
}