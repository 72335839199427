// external-source



.external-source{
    margin: 40px 0 0;
    font-family: @ff-roboto-cond;
    &_lbl{
        margin: 0 0 9px;
        font-size: 16px;
        line-height: 19px;
        color: #94918f;
        text-transform: uppercase;
    }
    &_val{
        font-size: 24px;
        font-weight: 700;
    }
}