// Font Face generator
// -------------------------------------------------------------


.fontFace(@fontName, @fontFile, @fontWeight: normal, @fontStyle: normal) {
    @font-face {
        font-family: "@{fontName}";
        src: url("../fonts/@{fontFile}.woff2") format("woff2"),
             url("../fonts/@{fontFile}.woff") format("woff");
        font-weight: @fontWeight;
        font-style: @fontStyle;
    }
}
