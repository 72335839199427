// utilities for response display



.display-media-util(@class) when (@class = col) {
    .d-block {
        display: block;
    }
    .d-none {
        display: none;
    }
}

.display-media-util(@class) {
    .d-@{class}-block {
        display: block;
    }
    .d-@{class}-none {
        display: none;
    }
}
