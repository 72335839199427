// contacts-house



.contacts-house{
    position: absolute;
    top: 90px;
    left: 0;
    max-width: 420px;
    font-family: @ff-russian-rail;
    font-size: 18px;
    line-height: 24/18;
    text-align: center;
    background: #fff;
    box-shadow: 0 40px 80px fade(#000, 20);
    b{
        font-weight: 500;
    }
    &_cover{
        text-align: center;
        img{
            width: 100%;
        }
    }
    &_cont{
        padding: 34px 15px 32px;
    }
    &_name{
        margin: 0 0 10px;
        font-size: 20px;
        font-weight: 700;
    }


    @media (max-width: @screen-tp-max) {
        position: relative;
        top: auto;
        left: auto;
        max-width: none;
        box-shadow: none;
        margin-left: -20px;
        margin-right: -20px;
        img{
            width: 100%;
        }
    }
}