// mobile-scroll



.mobile-scroll-mix(){
    height: auto;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);

    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox

    flex-wrap: nowrap;
    justify-content: normal;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb{ // Safari and Chrome
        width: 0 !important;
        height: 0 !important;
        display: none !important;
        background-color: transparent !important;
    }
}


.mobile-scroll{
    .mobile-scroll-mix();
}
