// upload btn input file


.btn-upload,
.btn-input-file{
    position: relative;
    overflow: hidden;
    display: inline-block;
    line-height: 1.1;
    input[type=file]{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        font-size: 600%;
        line-height: 600%;
        width: auto;
        height: auto;
        opacity: 0;
        cursor: pointer;
    }
}
.btn-input-file{
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
}
