// article-images



.article-images{
    overflow: hidden;
    width: 100vw;
    margin: 2em 0;
    transform: translateX(~"calc((970px - 100vw)/2)");
    -ms-transform: translateX(-50vw) translateX(485px);
    &_cont{
        margin: auto;
        font-size: 0;
        white-space: nowrap;
        text-align: center;
    }
    img{
        margin: 0 15px;
        max-width: 670px;
    }


    @media (max-width: 1199px) {
        &_cont{
            .mobile-scroll-mix();
            left: auto;
            max-width: none;
            margin-left: -5px;
        }
    }

    @media (max-width: 1010px) {
        transform: none;
        margin-left: -20px;
        margin-right: -20px;
        &_cont{
            padding: 0 10px;
        }
        img{
            max-width: 500px;
        }
    }

    @media (max-width: @screen-pl-max) {
        &_cont{
            white-space: normal;
            padding: 0;
        }
        img{
            max-width: 100%;
            margin: 4px 0;
        }
    }
}