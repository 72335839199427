// gallery-list



.gallery-list{
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    font-size: 0;
    &_i{
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin: 0 0 30px;
        padding: 0 15px;
    }
    &_in{
        position: relative;
        &:hover{
            .gallery-list_cover{
                filter: brightness(1.15) contrast(0.85) saturate(1.3);
            }
        }
    }
    &_cover{
        position: relative;
        background: no-repeat 0 0 / cover;
        text-align: center;
        color: #fff;
        transition: .4s;
        &:before{
            content: '';
            display: block;
            padding: 0 0 percentage(313/470);
        }
    }
    &_meta{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-family: @ff-russian-rail;
        line-height: 1;
    }
    &_count{
        font-size: 64px;
        font-weight: 700;
    }
    &_type{
        margin: 6px 0 0;
        font-size: 24px;
        font-weight: 500;
    }
    &_name{
        margin: 24px 0 17px;
        font-size: 16px;
        line-height: 19/16;
        font-weight: 700;
        a{
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
    &_text{
        font-size: 13px;
        line-height: 18/13;
    }


    @media (max-width: @screen-pl-max) {
        &_i{
            width: 100%;
        }
    }
}
