// BUTTON STYLES
// -------------

// Base styles
// --------------------------------------------------

// Core

.btn{
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 6px 9px;
    background: #e3e1dc;
    border: 1px solid #d7d6d3;
    font: 16px/18px @ff-russian-rail;
    text-align: center;
    text-shadow: none;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    border-radius: 0;
    &:hover{
        background: lighten(#e3e1dc, 2%);
        border-color: lighten(#d7d6d3, 2%);
        color: #000;
        text-decoration: none;
    }
    &:active, &.active{
        background: darken(#e3e1dc, 3%);
        border-color: darken(#d7d6d3, 3%);
        outline: none;
    }
    &:focus{
        outline: none;
        box-shadow: inset 0 0 0 1px #fff;
    }
    &[disabled], &.disabled{
        cursor: default;
        border-color: #d8d8d8;
        background-image: none;
        background-color: #f6f6f6;
        box-shadow: none;
        opacity: .5;
    }
}


// Set the backgrounds
// -------------------------
.btn-primary{
    border: 1px solid @base-color;
    background: @base-color;
    color: #fff;
    &:hover{
        border-color: lighten(@base-color, 5%);
        background-color: lighten(@base-color, 5%);
        color: #fff;
    }
    &.active, &:active{
        border-color: @base-color;
        background: @base-color;
    }
    &.disabled, &[disabled]{
        border-color: @base-color;
        background: @base-color;
    }
}



// Cross-browser Jank
// --------------------------------------------------

button.btn,
input[type="submit"].btn{
    &::-moz-focus-inner{
        padding: 0;
        border: 0;
    }
}

.btn-block-mix(){
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.btn-block{
    .btn-block-mix();
}

input[type="button"], input[type="reset"], input[type="submit"]{
    &.btn-block{
        width: 100%;
    }
}
