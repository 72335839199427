// article



.article{
    p, ol, ul{
        margin: 2em 0;
    }
    li{
        margin: 1em 0;
    }
    h2{
        @media (max-width: @screen-tp-max) {
            font-size: 24px !important;
        }
    }
}