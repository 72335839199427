// gallery



.gallery{
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    font-size: 0;
    &_i{
        display: inline-block;
        vertical-align: top;
        width: 33%;
        padding: 15px;
    }


    @media (max-width: @screen-tp-max) {
        margin: 0 -10px;
        &_i{
            padding: 10px;
        }
    }

    @media (max-width: @screen-pl-max) {
        margin: 0 -5px;
        &_i{
            width: 50%;
            padding: 5px;
        }
    }
}
