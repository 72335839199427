// header



.header{
    &_cont{
        position: relative;
        padding: 24px 0 21px;
        background: #fff;
    }
    &_logo{
        float: left;
        font-size: 53px;
        line-height: 0;
    }
    &_right{
        float: right;
        padding: 14px 0 0;
    }
    &_link-i{
        float: left;
        margin: 0 0 0 40px;
        &:first-child{
            margin-left: 0;
        }
    }
    &_link{
        font-family: @ff-russian-rail;
        font-size: 14px;
        line-height: 16px;
        font-weight: 800;
        color: @text-color;
    }

    &_menu{
        background: @secondary-color;
        box-shadow: 0 2px 5px fade(#28160c, 10);
        .fixed &{
            position: fixed;
            z-index: @zindex-modal - 10;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    &_menu-affix{
        .nav-open &{
            visibility: hidden;
            opacity: 0;
        }
    }

    &_m-menu{
        position: absolute;
        z-index: 98;
        top: 98px;
        left: 0;
        right: 0;
        //visibility: hidden;
        //opacity: 0;
        //transition: opacity .2s 0s, visibility .2s 0s;
        display: none;

        .nav-open &{
            //visibility: visible;
            //opacity: 1;
            display: block;
        }

        @media (max-width: @screen-tp-max) {
            top: 55px;
        }
    }

    @media (min-width: @screen-tp-min) {
        margin: 0 0 40px;
    }

    @media (max-width: @screen-tp-max) {
        &_cont{
            padding: 14px 0 11px;
        }
        &_logo{
            position: absolute;
            top: 50%;
            left: 50%;
            float: none;
            display: inline-block;
            vertical-align: top;
            font-size: 32px;
            transform: translate(-50%, -50%);
        }
        &_right{
            display: none;
        }
    }
}
