// news-nav



.news-nav{
    &:extend(.cf-mix all);
    margin: 0 0 37px;

    .pagination{
        float: right;
    }

    &__bottom{
        .news-tags,
        .pagination{
            &_i{
                &.active{
                    &:before{
                        top: auto;
                        bottom: 100%;
                        border-top-width: 0;
                        border-bottom-width: 6px;
                    }
                }
            }
        }
    }




    @media (max-width: @screen-tp-max) {
        .pagination{
            float: none;
            text-align: center;
            margin: 23px 0 0;
        }
    }
}