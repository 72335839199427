//
// Dropdowns
// --------------------------------------------------

.dropdown{
    position: relative;
    &.open{
        > .d-popup{
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
    }
    &.bottom-up{
        .d-popup{
            top: auto;
            bottom: 100%;
        }
    }
}
.d-switch{
    position: relative;
    cursor: pointer;
}

.d-popup{
    position: absolute;
    z-index: @zindex-dropdown;
    top: 100%;
    left: 0;
    min-width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .2s;
    transform-origin: 50% 0;
    transform: scaleY(.5);
}
