// news-tags



.news-tags{
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    &_i{
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 5px;
        font-family: @ff-russian-rail;
        font-size: 14px;
        line-height: 14px;
        color: #625d5a;
        text-align: center;
        background: #f1f1f1;
        &:first-child{
            margin-left: 0;
        }
        &:before{
            display: none;
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin: 0 0 0 -6px;
            border: 6px solid @base-color;
            border-right-color: transparent;
            border-left-color: transparent;
            border-bottom-width: 0;
        }
        &:hover{
            a.news-tags_link{
                background: darken(#f1f1f1, 10%);
            }
        }
        &.active{
            background: @base-color;
            color: #fff;
            &:before{
                display: block;
            }
            &:hover{
                a.news-tags_link{
                    background: @base-color;
                    cursor: default;
                }
            }
        }
    }
    &_link{
        display: block;
        padding: 10px 12px 8px;
        &, &:hover{
            color: inherit;
            text-decoration: none;
        }
    }


    @media (max-width: @screen-tp-max) {
        float: none;
        text-align: center;
    }
}