// presentations



.presentations{
    margin: 0;
    padding: 0;
    list-style: none;
    &_i{
        &:extend(.cf-mix all);
        margin: 0 0 30px;
        font-size: 16px;
        line-height: 19/16;
    }
    &_ico{
        position: relative;
        float: left;
        width: 170px;
        height: 170px;
        text-align: center;
        font-size: 0;
        background: #f3f3f3;
    }
    &_ico-img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    &_meta{
        float: right;
        width: 170px;
        padding: 11px 0 0;
    }
    &_cont{
        overflow: hidden;
        padding: 11px 30px 0;
    }
    &_name{
        font-size: 16px;
        font-weight: 700;
    }
    &_text{
        margin: 16px 0 0;
        font-size: 15px;
        line-height: 1.5;
    }
    &_type{
        font-weight: 700;
    }
    &_size{
        margin: 8px 0 16px;
        font-family: @ff-roboto-cond;
        color: #94918f;
    }
    &_link{
        font-weight: 700;
    }


    @media (max-width: @screen-tp-max) {
        &_i{
            margin: 0 0 40px;
        }
        &_meta{
            float: none;
            width: auto;
            overflow: hidden;
            padding-left: 20px;
        }
        &_cont{
            clear: both;
            padding: 20px 0 0;
        }
    }
}
