// f-social



.f-social{
    font-size: 0;
    line-height: 0;
    &_i{
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 11px;
        &:first-child{
            margin-left: 0;
        }
    }


    @media (max-width: @screen-pl-max) {
        text-align: center;
    }
}