// sub-menu



.sub-menu{
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: @ff-russian-rail;
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    white-space: nowrap;
    &_i{
        padding: 9px 0;
        &.active{
            .sub-menu_link{
                font-weight: 900;
            }
        }
    }
    &_link{
        color: @text-color;
        text-decoration: none;
    }

    @media (max-width: @screen-tp-max) {
        &_link{
            text-decoration: underline;
        }
    }
}