// menu



.header_menu{
    .menu{
        @h-offset: 30px;

        margin: 0 -@h-offset;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        font-size: 0;
        &_i{
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0 @h-offset;
            &.hide{
                display: none;
            }
            &.open{
                > .menu_drop{
                    opacity: 1;
                    visibility: visible;
                    transform: scaleY(1);
                }
            }
            &.active{
                background: #ed1131;
                box-shadow: 0 0 0 1px #ed1131;
            }
        }
        &_i-name{
            padding: 19px 0 17px;
            font-family: @ff-russian-rail;
            font-size: 16px;
            line-height: 18px;
            font-weight: 900;
            color: #fff;
            text-transform: uppercase;
            white-space: nowrap;
            cursor: pointer;
            &:after{
                content: '';
                display: inline-block;
                vertical-align: top;
                width: 12px;
                height: 7px;
                margin: 4px 0 0 3px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E %3Cpath d='M5.984 7.017a.827.827 0 0 1-.6-.248L.217 1.395A.842.842 0 0 1 .238.217a.842.842 0 0 1 1.178.021l4.568 4.754L10.552.238c.31-.331.847-.331 1.178-.021.33.31.33.848.02 1.178L6.583 6.769a.824.824 0 0 1-.599.248z' fill='%23FFF'/%3E %3C/svg%3E");
            }
        }
        &_drop{
            padding: 19px @h-offset;
            background: #fff;

            position: absolute;
            z-index: @zindex-dropdown;
            top: 100%;
            left: 0;
            min-width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: .2s;
            transform-origin: 50% 0;
            transform: scaleY(.5);
            box-shadow: 0 5px 20px fade(#000, 50);
        }

        &_more{
            display: none;
            &.show{
                display: inline-block;
            }

            .menu{
                &_drop{
                    left: auto;
                    right: 0;
                }
            }
        }


        @media (max-width: @screen-ld-max) {
            margin-left: -15px;
            margin-right: -15px;
            &_i{
                padding-left: 15px;
                padding-right: 15px;
            }
            &_drop{
                padding-left: 15px;
                padding-right: 15px;
            }
        }


        @media (max-width: @screen-tp-max) {
            display: none;
        }
    }
}