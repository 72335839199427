// content



.main{
    flex: 1 1 auto;
    .nav-open &{
        @media (max-width: @screen-tp-max) {
            height: 0;
            overflow: hidden;
        }
    }
}


.main-container{
    max-width: 1010px;
    margin: auto;
    padding: 0 20px 50px;
}