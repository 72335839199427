// page-meta



.page-meta{
    &:extend(.cf-mix all);
    font-family: @ff-russian-rail;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #94918f;
    &_tag{
        float: left;
        margin: 0 24px 0 0;
        padding: 12px 18px 10px;
        background: @base-color;
        color: #fff;
    }
    &_date{
        float: left;
        padding: 12px 0 10px;
    }
}