// menu-toggle



.menu-toggle{
    float: left;
    padding: 14px 3px;
    cursor: pointer;
    &_ico{
        position: relative;
        display: block;
        width: 24px;
        height: 2px;
        background: #454553;
        border-radius: 4px;
        &:before,
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #454553;
            border-radius: 4px;
            transform: translateY(-7px);
            transition: .2s;
        }
        &:after{
            transform: translateY(7px);
        }
    }
    &_text{
        position: absolute;
        top: 0;
        left: 100%;
        padding: 0 0 0 7px;
        font-size: 14px;
        line-height: 30px;
        font-weight: 500;
        color: #1a8cff;
        text-decoration: underline;
        visibility: hidden;
        opacity: 0;
        transform: translateX(20px);
        transition: .2s;
    }


    .nav-open &{
        .menu-toggle{
            &_ico{
                background: transparent;
                &:before{
                    transform: rotate(45deg);
                }
                &:after{
                    transform: rotate(-45deg);
                }
            }
            &_text{
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
                transition: .2s .1s;
            }
        }
    }


    @media (min-width: @screen-tp-min) {
        margin: 0 40px 0 0;
        padding-top: 23px;
        padding-bottom: 23px;
        &_ico{
            width: 36px;
            height: 3px;
            &:before,
            &:after{
                transform: translateY(-10px);
                height: 3px;
            }
            &:after{
                transform: translateY(10px);
            }
        }
    }
}
