// contacts



.contacts{
    &_text{
        margin: 0 0 35px;
        font-family: @ff-russian-rail;
        font-size: 24px;
        b{
            font-weight: 500;
        }
    }
    &_map{
        position: relative;
        margin: 0 0 -50px;
    }



    @media (max-width: @screen-tp-max) {
        &_text{
            font-size: 18px;
            [class^="col-"], [class*="col-"]{
                margin-top: 16px;
                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
}