// Quick floats
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

// Toggling content
.hide {
    display: none;
}
.show {
    display: block;
}

.hidden,
.hidden-container{
    display: none !important;
}


// Visibility
.invisible {
    visibility: hidden;
}

.relative {
    position:relative;
}
