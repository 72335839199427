// fonts



@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&subset=cyrillic');

// Roboto
@ff-roboto: 'Roboto', @font-family-sans-serif;
.ff-roboto{
    font-family: @ff-roboto;
}

// Roboto Condensed
@ff-roboto-cond: 'Roboto Condensed', @font-family-sans-serif;
.ff-roboto-cond{
    font-family: @ff-roboto-cond;
}



// RussianRailGPro
.fontFace('RussianRailGPro', 'RussianRailGPro-Reg', 400);
.fontFace('RussianRailGPro', 'RussianRailGPro-Medium', 500);
.fontFace('RussianRailGPro', 'RussianRailGPro-Bold', 700);
.fontFace('RussianRailGPro', 'RussianRailGPro-Extended', 800);
.fontFace('RussianRailGPro', 'RussianRailGPro-ExtendedBold', 900);

@ff-russian-rail: 'RussianRailGPro', @font-family-base;
.ff-russian-rail{
    font-family: @ff-russian-rail;
}
