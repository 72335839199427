// facts



.facts{
    margin-top: 38px;
    &_i{
        margin: 0 0 40px;
    }
    &_head{
        font-family: @ff-russian-rail;
        font-size: 40px;
        line-height: 1;
        color: @base-color;
    }
    &_text{
        margin: 8px 0 0;
        font-family: @ff-roboto-cond;
        font-size: 16px;
        line-height: 19/16;
        color: #625d5a;
    }


    @media (max-width: @screen-tp-max) {
        &_head{
            font-size: 24px;
        }
    }
}