// page-head



.page-head{
    margin: 0 0 36px;
    font-family: @ff-russian-rail;
    font-size: 32px;
    line-height: 28/24;
    font-weight: 900;
    word-wrap: break-word;
    word-break: break-all;
    h1{
        margin: 0;
        padding: 0;
        font: inherit;
        color: inherit;
    }


    @media (max-width: @screen-tp-max) {
        margin-bottom: 22px;
        font-size: 24px;
        &__news{
            text-align: center;
        }
    }
}