// contacts-map



.contacts-map{
    height: 570px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;


    @media (max-width: @screen-tp-max) {
        height: 400px;
    }
}