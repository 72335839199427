// news-subscribe



.news-subscribe{
    position: relative;
    float: right;
    margin: -78px 0 0;
    &_link{
        display: inline-block;
        vertical-align: top;
        padding: 15px 0 0;
        font-family: @ff-russian-rail;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: @link-color;
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: @link-hover-color;
        }
        .active > &,
        .success > &{
            display: none;
        }
    }
    &_form{
        display: none;
        border-collapse: separate;
        max-width: 460px;
        font-size: 0;
        .active > &{
            display: table;
        }
    }
    &_val{
        display: table-cell;
        vertical-align: top;
        float: left;
        height: 48px;
        padding: 15px 20px 13px;
        font-family: @ff-russian-rail;
        font-size: 16px;
        line-height: 18px;
        border-right: 0;
        background: #f1f1f1;
    }
    &_addon{
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }
    &_btn{
        display: inline-block;
        vertical-align: top;
        width: 147px;
        padding: 15px 10px 13px;
    }
    &_error{
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 12px;
        color: @base-color;
        .error &{
            display: block;
        }
        .success &{
            display: none;
        }
    }
    &_success{
        display: none;
        padding: 15px 0 0;
        font-size: 18px;
        .success &{
            display: block;
        }
    }


    @media (max-width: @screen-tp-max) {
        float: none;
        margin: -27px 0 25px;
        text-align: center;
        &_link{
            padding-bottom: 15px;
        }
        &_form{
            width: 100%;
            max-width: none;
        }
        &_success{
            padding-bottom: 8px;
        }
    }
}