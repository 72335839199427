// management



.management{
    margin-top: 38px;
    font-family: @ff-roboto-cond;
    font-size: 16px;
    line-height: 19/16;
    &_i{
        margin-bottom: 41px;
    }
    &_head{
        font-size: 24px;
        line-height: 28/24;
        font-weight: 700;
    }
    &_text{
        margin: 8px 0 0;
        color: #625d5a;
    }
}