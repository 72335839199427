.clearfix-mix() {
    &:before,
    &:after {
        display: table;
        content: "";
    }
    &:after {
        clear: both;
    }
}
.cf-mix{
    .clearfix-mix();
}
