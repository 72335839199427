// tether drop plugin


.drop-target{
    cursor: pointer;
}

.drop-down{
    .drop-content{
        display: none;
    }
}

.drop-element{
    position: absolute;
    display: none;
}

.drop-element.drop-open{
    display: block;
}


.drop{
    z-index: @zindex-modal + 10;
    &-element{
        transform: translateZ(0);
        transition: opacity .2s, visibility .2s;
        opacity: 0;
        visibility: hidden;
    }
    &-content{
        position: relative;
        transform: translateZ(0);
    }
    &-open{
        .drop-element&{
            display: none;
        }
    }
    &-open-transitionend{
        .drop-element&{
            display: block;
        }
    }
    &-after-open{
        //transition: none;
        opacity: 1;
        visibility: visible;
    }
}


.drop-theme-bounce{
    &.drop-element{
        &.drop-open{
            display: none;
        }
        &.drop-open-transitionend{
            display: block;
        }
        &.drop-after-open{
            transition: none;
            opacity: 1;
            .drop-content {
                transform: scale(1) translateZ(0);
            }
        }
    }


    .drop-content{
        transition: transform 0.2s cubic-bezier(0, 0, 0.265, 1.55);
        transform: scale(0) translateZ(0);
    }


    // Centers and middles
    &.drop-element-attached-bottom.drop-element-attached-center .drop-content{
        transform-origin: 50% 100%;
    }
    &.drop-element-attached-top.drop-element-attached-center .drop-content{
        transform-origin: 50% 0;
    }
    &.drop-element-attached-right.drop-element-attached-middle .drop-content{
        transform-origin: 100% 50%;
    }
    &.drop-element-attached-left.drop-element-attached-middle .drop-content{
        transform-origin: 0 50%;
    }


    // Top and bottom corners
    &.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content{
        transform-origin: 0 0;
    }
    &.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content{
        transform-origin: 100% 0;
    }
    &.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content{
        transform-origin: 0 100%;
    }
    &.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content{
        transform-origin: 100% 100%;
    }


    // Side corners
    &.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content{
        transform-origin: 0 0;
    }
    &.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content{
        transform-origin: 100% 0;
    }
    &.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content{
        transform-origin: 0 100%;
    }
    &.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content{
        transform-origin: 100% 100%;
    }
}


.drop-theme-arrow{
    .drop-content{
        box-shadow: none !important;
        filter: drop-shadow(0 5px 10px fade(#000, 50));
        &:before{
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 12px solid transparent;
        }
    }
    &.drop-element-attached-top.drop-element-attached-center .drop-content{
        margin: 12px 0 0;
        &:before{
            bottom: 100%;
            left: 50%;
            margin-left: -12px;
            border-bottom-color: #fff;
        }
    }
    &.drop-element-attached-bottom.drop-element-attached-center .drop-content{
        margin: 0 0 12px;
        &:before{
            top: 100%;
            left: 50%;
            margin-left: -12px;
            border-top-color: #fff;
        }
    }
}


.drop-theme-simple{
    .drop-content{
        padding: 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 5px 10px fade(#000, 50);
    }
}


