// scaffolding
// -------------------------------------------------------------

*, *:before, *:after {
    box-sizing: border-box;
}


// Body reset
html {
    height: 100%;
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
    position: relative;
    min-height: 100%;
    height: auto;
    margin: 0;
    font-family: @font-family-base;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @text-color;
    background: #fff;

    display: flex;
    flex-direction: column;
}




// Links
a {
    color: @link-color;
    text-decoration: underline;

    &:hover{
        color: @link-hover-color;
        text-decoration: underline;
        outline: none;
    }

    &:focus{
        outline: none;
    }
}


// Images
img {
    vertical-align: middle;
    max-width: 100%;
    border: 0;
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 1em;
    margin-bottom: .5em;
}

hr {
    margin: 1.5em 0;
    border: 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #fff;
}
