// pagination



.pagination{
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    &_i{
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 5px;
        font-family: @ff-russian-rail;
        font-size: 14px;
        line-height: 14px;
        color: #625d5a;
        text-align: center;
        background: #f1f1f1;
        &:first-child{
            margin-left: 0;
        }
        &:before{
            display: none;
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin: 0 0 0 -6px;
            border: 6px solid @base-color;
            border-right-color: transparent;
            border-left-color: transparent;
            border-bottom-width: 0;
        }
        &:hover{
            a.pagination_link{
                background: darken(#f1f1f1, 10%);
            }
        }
        &.active{
            background: @base-color;
            color: #fff;
            &:before{
                display: block;
            }
            &:hover{
                a.pagination_link{
                    background: @base-color;
                    cursor: default;
                }
            }
        }

        &.prev,
        &.next{
            background: none;
            .pagination_link{
                padding-left: 5px;
                padding-right: 5px;
                font: 0/0 a;
                &:hover{
                    background: none;
                    &:after{
                        border-left-color: #000;
                        border-right-color: #000;
                    }
                }
                &:after{
                    content: '';
                    display: block;
                    margin: 1px 0;
                    border-left: 8px solid #39322f;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                }
            }
            &.disabled{
                opacity: .2;
                .pagination_link{
                    cursor: default;
                    &:hover{
                        &:after{
                            border-left-color: #39322f;
                            border-right-color: #39322f;
                        }
                    }
                }
            }
        }
        &.prev{
            .pagination_link{
                &:after{
                    border-left: 0;
                    border-right: 8px solid #39322f;
                }
            }
        }
        &.next{
            @media (min-width: @screen-tl-min) {
                margin-right: -23px;
            }
        }
    }
    &_link{
        display: block;
        padding: 10px 12px 8px;
        &, &:hover{
            color: inherit;
            text-decoration: none;
        }
    }
}