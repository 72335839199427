// make grid mixin



.make-grid() {
    .calc-grid-column(@index, @type) when (@type = width) {
        .@{class}-@{index} {
            width: percentage( @index / @columns );
        }
    }
    .calc-grid-column(@index, @type) when (@type = offset) {
        .@{class}-offset-@{index} {
            margin-left: percentage((@index / @columns));
        }
    }
    .calc-grid-column(@index, @type) when (@type = push) {
        .@{class}-push-@{index} {
            left: percentage((@index / @columns));
        }
    }
    .calc-grid-column(@index, @type) when (@type = pull) {
        .@{class}-pull-@{index} {
            right: percentage((@index / @columns));
        }
    }

    .loop-grid-columns(@index, @class, @type) when (@index > 0) {
        .make-grid > .calc-grid-column(@index, @type);
        .make-grid > .loop-grid-columns((@index - 1), @class, @type);
    }


    .make(@class, @columns, @gutter-width) {
        @clazz: ~'[class^="@{class}-"], [class*=" @{class}-"]';
        @{clazz} {
            float: left;
            min-height: 1px;
            padding-left: @gutter-width/2;
            padding-right: @gutter-width/2;
        }

        @clazz-pull: ~'[class^="@{class}-pull-"], [class*=" @{class}-pull-"]';
        @{clazz-pull} {
            position: relative;
        }

        @clazz-push: ~'[class^="@{class}-push-"], [class*=" @{class}-push-"]';
        @{clazz-push} {
            position: relative;
        }

        .make-grid > .loop-grid-columns(@columns, @class, width);
        .make-grid > .loop-grid-columns(@columns, @class, offset);
        .make-grid > .loop-grid-columns(@columns, @class, push);
        .make-grid > .loop-grid-columns(@columns, @class, pull);
    }
}
