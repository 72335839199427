// page-search



.page-search{
    display: table;
    border-collapse: separate;
    width: 100%;
    margin: 0 0 40px;
    &_val{
        display: table-cell;
        vertical-align: top;
        float: left;
        height: 64px;
        padding: 22px 24px 20px;
        border-right: 0;
    }
    &_addon{
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }
    &_btn{
        padding: 22px 29px;
    }
}