// geography-table



.geography-table{
    margin: 0 0 40px;
    font-family: @ff-russian-rail;
    font-size: 16px;
    line-height: 18px;
    box-shadow: 0 20px 40px fade(#000, 10);
    &_table{
        width: 100%;
    }
    thead{
        th{
            padding: 30px 40px;
            color: #fff;
            text-align: left;
            font-weight: 700;
            text-transform: uppercase;
            background: #39322f;
        }
    }
    tbody{
        tr{
            &:nth-child(even){
                td{
                    background: #f9f9f9;
                }
            }
        }
        tr{
            &:hover{
                td{
                    background: @base-color;
                    color: #fff;
                    .geography-table_type{
                        color: #fff;
                    }
                }
            }
        }
        td{
            vertical-align: top;
            padding: 39px 40px;
            cursor: pointer;
        }
    }

    &_org{
        margin: 14px 0 0;
        &:first-child{
            margin-top: 0;
        }
    }
    &_type{
        margin: 6px 0 0;
        font-size: 12px;
        line-height: 14px;
        color: #94918f;
        text-transform: uppercase;
    }


    @media (max-width: @screen-tp-max) {
        margin-left: -20px;
        margin-right: -20px;
        font-size: 14px;
        thead{
            th{
                padding: 10px 15px;
            }
        }
        tbody{
            td{
                padding: 14px 15px;
            }
        }
    }

    @media (max-width: 374px) {
        font-size: 12px;
        thead{
            th{
                padding: 5px 10px;
            }
        }
        tbody{
            td{
                padding: 9px 10px;
            }
        }
    }
}