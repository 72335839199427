// From control mix
// ----------------------------------

.form-control-mix{
    display: block;
    width: 100%;
    height: 32px;
    margin: 0;
    padding: 5px;
    font-family: @ff-russian-rail;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    background: #fff;
    border: 1px solid #979797;
    -webkit-appearance: none;
    .placeholder(#afaeae);

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus{
        border-color: @link-color;
        .placeholder(#bfbfbf);
    }

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background-color: #f5f5f5;
        border-color: #ddd;
        cursor: not-allowed;
        opacity: 1; // iOS fix for unreadable disabled content
    }

    // Reset height for `textarea`s
    textarea& {
        height: auto;
        resize: none;
    }
}
