// more-menu



.more-menu{
    display: block;
    padding: 10px 0 0;
    font-size: 0;
    white-space: nowrap;
    .menu{
        &_i{
            display: none;
            &.show{
                display: inline-block;
                vertical-align: top;
            }
            &.active{
                background: none;
                box-shadow: none;
                .menu_i-name{
                    color: @base-color;
                }
            }
        }
        &_i-name{
            color: @text-color;
            padding: 0;
            &:after{
                display: none;
            }
        }
        &_drop{
            position: static;
            opacity: 1;
            transition: none;
            transform: none;
            padding: 0;
            margin: 0;
            box-shadow: none;
            .open &{
                visibility: visible;
            }
        }
    }
    .sub-menu{
        margin: 10px 0 0;
    }
}