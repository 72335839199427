// GRID SYSTEM
// -----------


.make-grid > .make(col, @grid-columns, @grid-gutter-width);
.display-media-util(col);


@media (min-width: @screen-pp-min) {
    .make-grid > .make(m-col-pp, @grid-columns, @grid-gutter-width);
    .display-media-util(pp);
}

@media (min-width: @screen-pl-min) {
    .make-grid > .make(m-col-pl, @grid-columns, @grid-gutter-width);
    .display-media-util(pl);
}

@media (min-width: @screen-tp-min) {
    .make-grid > .make(m-col-tp, @grid-columns, @grid-gutter-width);
    .display-media-util(tp);
}

@media (min-width: @screen-tl-min) {
    .make-grid > .make(m-col-tl, @grid-columns, @grid-gutter-width);
    .display-media-util(tl);
}

@media (min-width: @screen-ld-min) {
    .make-grid > .make(m-col-ld, @grid-columns, @grid-gutter-width);
    .display-media-util(ld);
}


.wrap{
    &:extend(.cf-mix all);
    width: auto;
    max-width: @container-width + @grid-gutter-width;
    min-width: 0;
    margin: auto;
    padding-left: @grid-gutter-width / 2;
    padding-right: @grid-gutter-width / 2;
}
.wrap-row{
    &:extend(.cf-mix all);
    margin-left: @grid-gutter-width / -2;
    margin-right: @grid-gutter-width / -2;
}
