// geography-map



.geography-map{
    position: relative;
    margin: -80px -89px 60px;
    svg{
        vertical-align: top;
    }
    .active-region{
        fill: #714F54;
        stroke: #454553;
        stroke-width: 1;
        cursor: pointer;
        &.hover,
        &.active{
            fill: @base-color;
            stroke: @base-color;
        }
    }

    @media (max-width: 1147px) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0);
    }
}

.svg-map-geo{
    polygon, path{
        fill: #f1f1f1;
        stroke: #6c7c86;
        stroke-width: .5;
    }
}


.map-city{
    position: absolute;
    margin: -38px 0 0 -13px;
    transition: .2s;
    &_hover{
        position: absolute;
        top: 0;
        left: 0;
        width: 27px;
        height: 39px;
        cursor: pointer;
    }
    &_mark{
        position: absolute;
        top: 0;
        left: 0;
        width: 76px;
        height: 45px;
        background: url(../i/geo-map-mark.svg) no-repeat;
        pointer-events: none;
    }
    &_name{
        position: absolute;
        z-index: 2;
        top: 5px;
        left: 29px;
        padding: 2px 7px;
        font-family: @ff-roboto-cond;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        color: #000;
        background: fade(#fff, 80);
        border-radius: 20px;
        pointer-events: none;
    }
    &.hover{
        z-index: 30;
        .map-city_mark{
            background-image: url(../i/geo-map-mark-active.svg);
        }
    }
    &.active{
        .map-city_name{
            display: none;
        }
    }
    &.disabled{
        opacity: .5;
    }
    &_drop{
        display: none;
    }

    &.left{
        .map-city_name{
            left: auto;
            right: 100%;
            margin-right: 2px;
        }
    }
}



.city-drop{
    position: relative;
    width: 288px;
    margin: 0 0 0 13px;
    padding: 24px 20px 20px;
    font-family: @ff-russian-rail;
    background: #fff;
    box-shadow: 20px 20px 60px fade(#000, 50);
    &:before{
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -16px;
        margin-right: -13px;
        border-right: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    &_close{
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        .cross(15, 2, #ccc);
    }
    &_name{
        margin: 0 0 15px;
        font-size: 22px;
        line-height: 1.1;
        font-weight: 700;
    }
    &_image{
        margin: 0 0 21px;
    }
    .drop-element-attached-right &{
        margin: 0 13px 0 0;
        &:before{
            right: auto;
            left: 100%;
            margin-right: 0;
            margin-left: -13px;
            border-right: 0;
            border-left: 10px solid #fff;
        }
    }
}



.geography-drop.drop-element{
    @media (max-width: @screen-tp-max) {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0) !important;
        background: fade(#fff, 85);
        .drop-content{
            padding: 30px 0;
            &.center{
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }
        }
        .city-drop{
            margin: 0 auto !important;
            &:before{
                display: none;
            }
            &_close{
                display: block;
            }
        }
    }
}