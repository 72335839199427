// welcome



.welcome{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    min-height: ~"calc(100vh - 306px)";
    color: #fff;

    &_bg,
    &_bg-i{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &_bg-i{
        background: no-repeat 50% 100% / cover;
        opacity: 0;
        transition: 1.5s;
        &.active{
            opacity: 1;
        }
    }

    &_wrap{
        position: relative;
        flex: 1 1 auto;
        max-width: 1170px;
        margin: auto;
        padding: 60px 30px;
    }
    &_cont{
        float: left;
        width: 50%;
        font-family: @ff-russian-rail;
        font-weight: 900;
        text-transform: uppercase;
    }
    &_head{
        margin: 0 0 64px;
        font-size: 40px;
        line-height: 48/40;
        font-weight: 900;
        filter: drop-shadow(0 0 50px fade(#000, 50));
        h1{
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            margin: 0;
        }
    }
    &_text{
        font-size: 24px;
        line-height: 1.3;
    }

    &_carousel{
        float: left;
        width: 50%;
        padding-left: 100px;
    }

    &_carousel-arrows{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &_carousel-arrow{
        position: absolute;
        top: 50%;
        left: 0;
        margin: -17px 0 0 -90px;
        font-size: 0;
        line-height: 0;
        opacity: .65;
        transition: .3s;
        cursor: pointer;
        &:hover{
            opacity: 1;
        }
        .arrow{
            fill: #fff;
        }
    }
    &_carousel-next{
        left: auto;
        right: 0;
        margin-left: 0;
        margin-right: -90px;
    }

    &_carousel-dots{
        position: absolute;
        bottom: -28px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 0;
        white-space: nowrap;
    }
    &_carousel-dot{
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 8px;
        margin: 0 5px;
        opacity: .3;
        transition: .3s;
        cursor: pointer;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #fff;
        }
        &:hover{
            opacity: .6;
        }
        &.is-selected{
            opacity: 1;
        }
    }


    @media (max-width: 1400px) {
        &_wrap{
            padding-left: 40px;
            padding-right: 40px;
        }
        &_carousel-prev{
            margin-left: -50px;
        }
        &_carousel-next{
            margin-right: -50px;
        }
    }

    @media (max-width: 1300px) {
        &_carousel-prev{
            margin-left: 0;
        }
        &_carousel-next{
            margin-right: 0;
        }
    }



    @media (min-width: @screen-tp-min) {
        margin: -40px 0 0;
    }

    @media (max-width: @screen-tl-max) {
        &_wrap{
            padding-left: 50px;
            padding-right: 50px;
        }
        &_carousel-prev{
            margin-left: 10px;
        }
        &_carousel-next{
            margin-right: 10px;
        }

        &_cont{
            float: none;
            width: auto;
            max-width: 520px;
        }
        &_carousel{
            float: none;
            width: auto;
            padding: 0;
            margin: 60px 0 0;
        }
    }

    @media (max-width: @screen-tp-max) {
        min-height: ~"calc(100vh - 55px)";
        &_head{
            margin: 0 0 36px;
            font-size: 18px;
            line-height: 18px;
        }
        &_text{
            font-size: 16px;
        }
    }
}