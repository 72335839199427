//
// Tables.less
// Tables for, you guessed it, tabular data
// ----------------------------------------

// BASE TABLES
// -----------------

table {
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

// BASELINE STYLES
// ---------------

.table {
    width: 100%;
    th,
    td {
        padding: 8px;
        line-height: @line-height-base;
        text-align: left;
        border-top: 1px solid #ddd;
    }
    th {
        font-weight: 700;
        vertical-align: bottom;
    }
    td {
        vertical-align: top;
    }
    // Remove top border from thead by default
    thead:first-child tr th,
    thead:first-child tr td {
        border-top: 0;
    }
    // Account for multiple tbody instances
    tbody + tbody {
        border-top: 2px solid #ddd;
    }
}
