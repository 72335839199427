// MODALS
// ------


.modal-backdrop{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @zindex-modal-background;
    &.fade {
        opacity: 0;
    }
}
.modal-backdrop {
    background-color: #132129;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: .9;
}


.theaterMode{
    overflow: hidden;
}



.modal-dialog{
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    text-align: center;
    z-index: @zindex-modal;
    white-space: nowrap;
    overflow: auto;
    &:after{
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
        content: '';
    }
}
.modal{
    display: inline-block;
    position: relative;
    z-index: @zindex-modal;
    max-width: 950px;
    margin: 20px 0;
    border: 1px solid #afafaf;
    font-size: @font-size-base;
    color: #000;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    white-space: normal;
    &.alert-modal{
        min-width: 0;
        max-width: none;
        width: 490px;
    }
}

